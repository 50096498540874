<template>
  <v-card width="350" class="align-center d-flex flex-column margin-card">
    <v-card-title>
      <div>
        <v-img :src="require('@/assets/logo-grupo-cometa.svg')"> </v-img>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <h2 class="mb-5">Reenviar confirmação cadastro</h2>
        <v-text-field
          class="mb-2"
          v-model="form.email"
          :rules="[rules.required, rules.emailCometa]"
          label="E-mail"
          outlined
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-row class="col-md-12 col-sm-12 mb-3">
      <v-btn
        @click="resendConfirmationRegistration()"
        block
        :disabled="!valid"
        color="#0d47a1"
        class="white--text"
        >Enviar
      </v-btn>
    </v-row>
    <v-overlay :value="load">
      <v-progress-circular size="130" color="white" indeterminate>
        Reenviando...
      </v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import loginService from "../../services/login";
import rules from "../../mixins/inputRules";
export default {
  mixins: [rules],
  data() {
    return {
      form: {
        email: null
      },
      valid: true,
      load: false
    };
  },
  methods: {
    async resendConfirmationRegistration() {
      try {
        this.load = true;

        await loginService.resendConfirmationRegistration({
          email: this.form.email
        });
        this.$notify({
          text: "Verifique sua caixa de entrada!",
          type: "success"
        });
      } catch (error) {
        this.$notify({
          text: "Erro ao reenivar confirmação cadastro",
          type: "error"
        });
      } finally {
        this.load = false;
      }
    }
  }
};
</script>

<style>
.margin-card {
  margin-bottom: 10%;
}
</style>
